import React from "react";
import tw from "twin.macro";
import { useIntl } from "gatsby-plugin-intl";

const Pagination = ({ page, pages, total, elements, limit, prevPage, nextPage }) => {    

    const intl = useIntl();
    return (
        <>
            <nav
                css={[
                    tw`bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6 font-montserrat`,
                ]}
                aria-label="Pagination"
            >
                {/** Textos de resultados */}
                <div css={[tw`hidden sm:block`]}>
                    <p css={[tw`text-sm text-gray-400`]}>
                        {intl.formatMessage({ id: "49" })}
                        <span css={[tw`font-medium mx-1`]}>
                            {Number(page - 1) * Number(limit) + 1}
                        </span>
                        {intl.formatMessage({ id: "50" })}
                        <span css={[tw`font-medium mx-1`]}>
                            {Math.min(Number(page - 1) * Number(limit) + Number(limit), total)}
                        </span>
                        {intl.formatMessage({ id: "51" })}
                        <span css={[tw`font-medium mx-1`]}>{total}</span>
                        {intl.formatMessage({ id: "52" })}
                    </p>
                </div>

                {/** Botones Anterior y Siguiente */}
                <div css={[tw`flex-1 flex justify-between sm:justify-end`]}>
                    <button
                        disabled={page === 1}
                        css={[
                            tw`duration-300 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 mr-5`,
                            page === 1 ? [tw`cursor-not-allowed mr-5 opacity-50`] : null,
                        ]}
                        onClick={() => {
                            prevPage && prevPage();
                        }}
                    >
                        {intl.formatMessage({ id: "53" })}
                    </button>

                    <button
                        disabled={page === pages}
                        css={[
                            tw`duration-300 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50`,
                            page === pages ? [tw`cursor-not-allowed opacity-50`] : null,
                        ]}
                        onClick={() => {
                            nextPage && nextPage();
                        }}
                    >
                        {intl.formatMessage({ id: "54" })}
                    </button>
                </div>
            </nav>
        </>
    );
};

export default Pagination;
