import React from "react";
import SEO from "../components/Seo";
import { useIntl } from "gatsby-plugin-intl";
import SecureLayout from "../components/SecureLayout";
import tw from "twin.macro";
// import firebase from "gatsby-plugin-firebase";
//import { FirebaseAuthContext } from "../context/FirebaseAuthContext";
import Stats from "../components/common/Stats";
import Notices from "../components/common/Notices";
import Users from "../components/common/Users.js";
import Skills from "../components/common/Skills.js";

const Index = () => {
    const intl = useIntl();

    return (
        <>
            <SecureLayout
                path={"/login"}
                roles={["admin", "editor"]}
                title={intl.formatMessage({ id: "2" })}
            >
                <SEO title={intl.formatMessage({ id: "2" })} />
                <div css={[tw`mb-10`]}>
                    <Stats />
                </div>

                <div css={[tw`mb-10`]}>
                    <Notices />
                </div>

                <div css={[tw`mb-10`]}>
                    <Users />
                </div>

                <div css={[tw`mb-10`]}>
                    <Skills />
                </div>
            </SecureLayout>
        </>
    );
};

export default Index;
