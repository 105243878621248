import React from "react";
import tw from "twin.macro";

const Loading = () => {
    return (
        <img
            css={[tw`inline-block w-10 h-10`]}
            src={require(`../../images/animations/spinner3.svg`)}
            alt="loading"
        />
    );
};

export default Loading;
