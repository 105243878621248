import React, { useEffect, useState, useContext } from "react";
import tw from "twin.macro";
import { useIntl } from "gatsby-plugin-intl";
import { FiUsers } from "react-icons/fi";
import { GiSkills } from "react-icons/gi";
import { FaTasks } from "react-icons/fa";
import { GiNewspaper } from "react-icons/gi";
import { FirebaseAuthContext } from "../../context/FirebaseAuthContext";

const Stats = () => {
    const intl = useIntl();
    const { updateIdToken } = useContext(FirebaseAuthContext);
    const [statsData, setStatsData] = useState({
        users: undefined,
        skills: undefined,
        tasks: undefined,
        notices: undefined,
    });

    /// https://dev.to/pallymore/clean-up-async-requests-in-useeffect-hooks-90h

    // Cuando hay token nuevo recargamos
    useEffect(() => {
        let isCancelled = false;        
        const fetchData = async () => {
            const token = await updateIdToken();
            token && fetch(`${process.env.GATSBY_API_URL}/v1/dashboard/statistics`, {
                method: "GET",
                headers: { Authorization: `Bearer ${token}` },
            })
                .then((response) => {
                    if (!response.ok) {
                        throw response;
                    }
                    return response.json();
                })
                .then((data) => {
                    if (!isCancelled) {
                        setStatsData(data);
                    }
                })
                .catch(() => {
                    if (!isCancelled) {
                        setStatsData({ users: null, skills: null, tasks: null, notices: null });
                    }
                });
        };

        fetchData();

        return () => {
            isCancelled = true;
        };
    }, [updateIdToken]);

    return (
        <div css={[tw`m-5`]}>
            <h3 css={[tw`text-lg leading-6 font-medium font-montserrat text-gray-900`]}>
                {intl.formatMessage({ id: "33" })}
            </h3>
            <dl css={[tw`mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-4`]}>
                <Stat
                    value={statsData.users}
                    title={intl.formatMessage({ id: "28" })}
                    icon={FiUsers}
                    color={"primary"}
                />
                <Stat
                    value={statsData.skills}
                    title={intl.formatMessage({ id: "29" })}
                    icon={GiSkills}
                    color={"secondary"}
                />
                <Stat
                    value={statsData.tasks}
                    title={intl.formatMessage({ id: "30" })}
                    icon={FaTasks}
                    color={"ternary"}
                />
                <Stat
                    icon={GiNewspaper}
                    value={statsData.notices}
                    title={intl.formatMessage({ id: "31" })}
                    linkTitle={intl.formatMessage({ id: "32" })}
                    color={"quaternary"}
                />
            </dl>
        </div>
    );
};

const Loading = () => {
    return (
        <img
            css={[tw`inline-block w-6 h-6`]}
            src={require(`../../images/animations/spinner2.svg`)}
            alt="loading"
        />
    );
};

const Stat = ({ title, value, icon, color }) => {
    const TheIcon = icon;

    const generateColor = (color) => {
        switch (color) {
            case "primary":
                return tw`bg-primary`;

            case "secondary":
                return tw`bg-secondary`;

            case "ternary":
                return tw`bg-ternary`;

            default:
                return tw`bg-quaternary`;
        }
    };

    const computeValue = (v) => {
        if (v === undefined) {
            return <Loading />;
        } else if (v === null) {
            return "-";
        } else {
            return v;
        }
    };

    return (
        <div css={[tw`flex flex-col bg-white overflow-hidden shadow rounded-lg font-montserrat`]}>
            <div css={[tw`flex-grow px-4 py-5 sm:p-6`]}>
                <div css={[tw`flex items-center`]}>
                    <div css={[tw`flex-shrink-0 rounded-md p-3`, generateColor(color)]}>
                        <TheIcon css={[tw`h-6 w-6 text-white`]} />
                    </div>
                    <div css={[tw`ml-5 w-0 flex-1`]}>
                        <dt css={[tw`text-sm font-medium text-gray-500 truncate`]}>{title}</dt>
                        <dd css={[tw`flex items-baseline`]}>
                            <div css={[tw`text-2xl font-semibold text-gray-900`]}>
                                {computeValue(value)}
                            </div>
                        </dd>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Stats;
